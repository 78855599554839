@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); 

* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #52db29;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #52db29;
}
.content {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

/* query previews / list */ 
.query-preview {
  padding: 10px 18px; 
  margin: 20px 0; 
  border-bottom: 1px solid #fafafa; 
}

.query-preview:hover {
  box-shadow: 1px 3px 5px rgba(0,0,0,0.1); 
}

.query-preview h2 {
  font-size: 20px; 
  color: #52db29;
  margin-bottom: 8px;  
}

.query-preview a{
  text-decoration: none
}

/* create new query form */
.create {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.create label {
  text-align: left;
  display: block;
}
.create h2 {
  font-size: 20px;
  color: #2cc4d8;
  margin-bottom: 30px;
}
.create input, .create textarea, .create select {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  box-sizing: border-box;
  display: block;
}

.create-query {
  background-color: #df0a0a;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

/* excel export */

.export-excel {
  background-color: #2fd629;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

/* home page */
.home {
  text-align: center; 
}

.home button {
  background: #2cc4d8;
  color: #fff;
  border: 2px solid #2cc4d8;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;  
  font-size: 20px; 
  transition-duration: 0.4s; 
  margin-right: 10px; 
  margin-bottom: 10px;
}

.home button:hover {
  background-color: white;
  color: #2cc4d8; 
}

.home a {
  margin: auto 0; 
}

.home h2 {
  color: #2cc4d8;  
}

/* line graphs */
.graph-container {
  border: 2px solid black; 
  padding: 10px;
  margin-bottom: 20px; 
  border-radius: 8px;
}